<template>
  <div class="panel-margin relative pb-2 _question-panel">
    <div class="__difficulty-text">
      <difficulty-indicator :difficulty="_.get(item, 'data.difficulty', '')"/>
    </div>
    <div class="flex mb-4">
      <div class="bg-grid-color-secondary mr-2" style="width: 100%">
        <ComplexEditor
          :questionnaire_id="parent_id"
          v-bind:editor_data.sync="editorData"
          v-on:blur="onBlur"
          v-on:destroy="onDestroy"
          :placeholder="`${$t('introducao')}...`"
        ></ComplexEditor>
      </div>
    </div>

    <div class="flex mb-8 mt-8 gap-gaps">
      <div class="bg-grid-color-secondary mr-2" style="width: 100%">

        <div class="flex flex-wrap">
          <div v-for="(option, index) in options" :key="option" class="flex flex-wrap">

            <span v-if="option.type === 'text'" style="display: flex; align-items: center;">
              <TitleComponent
                :item="option"
                type="option"
                placeholder="Texto..."
                @title-TITLE_UPDATED="updateOptionTitle"
                @title-ENTER_PRESSED="addOption(option.type, index)"
              ></TitleComponent>
            </span>
            <span v-else style="display: flex;align-items: center;">
              <TitleComponent
                :item="option"
                type="option"
                placeholder="Lacuna..."
                :class="{'gap-class mb-2' : option.type === 'gap', 'magnetic-gap-class mb-2' : option.type === 'magnetic_gap'}"
                @title-TITLE_UPDATED="updateOptionTitle"
                @title-ENTER_PRESSED="addOption(option.type, index)"
              ></TitleComponent>
            </span>
            <span style="display: flex;align-items: center;">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" v-click-outside>
                <vs-button
                    type="filled"
                    icon="add_circle_outline"
                    class="float-left footer-button ml-2"
                    v-tooltip.bottom-start="{
                      content: 'Adicionar texto',
                      delay: { show: 1000, hide: 100 }
                    }"
                  ></vs-button>
                <vs-dropdown-menu>
                  <ul class="vx-navbar__starred-pages-more--list">
                      <li
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="addOption('text', index)"
                      >
                        <i
                          class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                          style="font-size: 20px"
                          >text_fields</i
                        >
                        <span class="px-2 pt-2 pb-1">{{ $t('adicionar-texto') }}</span>
                      </li>

                      <li
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="addOption('gap', index)"
                      >
                        <i
                          class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                          style="font-size: 20px"
                          >space_bar</i
                        >
                        <span class="px-2 pt-2 pb-1">{{ $t('adicionar-lacuna') }}</span>
                      </li>

                      <li
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="addOption('magnetic_gap', index)"
                      >
                        <font-awesome-icon icon="magnet" />
                        <span class="px-2 pt-2 pb-1">{{ $t('adicionar-lacuna-magnetica') }}</span>
                      </li>

                      <vs-divider class="m-1"/>

                      <li
                        v-if="option.type !== 'text'"
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="changeOptionType(option, 'text', index)"
                      >
                        <i
                          class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                          style="font-size: 20px"
                          >text_fields</i
                        >
                        <span class="px-2 pt-2 pb-1">{{ $t('transformar-em-texto') }}</span>
                      </li>

                      <li
                        v-if="option.type !== 'gap'"
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="changeOptionType(option, 'gap', index)"
                      >
                        <i
                          class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                          style="font-size: 20px"
                          >space_bar</i
                        >
                        <span class="px-2 pt-2 pb-1">{{ $t('transformar-em-lacuna') }}</span>
                      </li>

                      <li
                        v-if="option.type !== 'magnetic_gap'"
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="changeOptionType(option, 'magnetic_gap', index)"
                      >
                        <font-awesome-icon icon="magnet" />
                        <span class="px-2 pt-2 pb-1">{{ $t('transformar-em-lacuna-magnetica') }}</span>
                      </li>

                      <vs-divider class="m-1" v-if="options.length > 1"/>

                      <li
                        class="starred-page--more flex items-center cursor-pointer"
                        @click="deleteOption(index)"
                        v-if="options.length > 1"
                      >
                        <i
                          class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                          style="font-size: 20px"
                          >delete</i
                        >
                        <span class="px-2 pt-2 pb-1">{{ $t('remover') }}</span>
                      </li>
                  </ul>
                </vs-dropdown-menu>
              </vs-dropdown>
            </span>
          </div>
        </div>
      </div>
    </div>

    <Footer :showInvalidateButton="showInvalidateButton" :item="item" :parent_id="parent_id" :cheetEnabled="cheetEnabled"></Footer>
  </div>
</template>

<script>
import TitleComponent from './title/TitleComponent'
import ComplexEditor from '../ComplexEditor'
import Footer from '../Footer'
import vClickOutside from 'v-click-outside'
import DifficultyIndicator from '@/components/DifficultyIndicator.vue'

export default {
  components: {
    TitleComponent,
    ComplexEditor,
    Footer,
    vClickOutside,
    DifficultyIndicator
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
    showInvalidateButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cheetEnabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showComplexEditor: false,
      editorData: this.item !== null ? this.item.data.title : ''
    }
  },
  computed: {
    options() {
      return this.item.data.options
    }
  },
  methods: {
    updateOptionTitle(item, type, value) {
      item.label = value
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    changeOptionType(option, type, index) {
      option.type = type
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onBlur() {
      this.item.data.title = this.editorData
      this.item.data.editor_type = 'complex'
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onEditorDestroy() {
      this.item.data.title = this.html2text(this.editorData)
      this.item.data.editor_type = 'complex'
    },
    addOption(type, index) {
      const option = {
        id: this.$uuidKey(),
        type: type
      }
      this.options.splice(index + 1, 0, option)
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    deleteOption(index) {
      this.options.splice(index, 1)
      this.addBlankTextOption()
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    addBlankTextOption() {
      if (this.options.length === 0) {
        this.options.push({
          type: 'text'
        })
      }
    }
  },
  mounted() {

    this.$emit('questionRendered')

    this.addBlankTextOption()

    this.$on('item-TITLE_UPDATED', (item, type, value) => {
      if (this.item.id === item.id) {
        const data = this._.cloneDeep(event.data)
        data.title = value
        this.editorData = value
        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
    })

    this.$on('footer-DELETE_ITEM', (item) => {
      this.$parent.$emit('item-DELETE_ITEM', item)
    })

    this.$on('footer-INVALIDATE_ITEM', (item) => {
      this.$parent.$emit('item-INVALIDATE_ITEM', item)
    })

    this.$on('footer-REVALIDATE_ITEM', (data) => {
      this.$parent.$emit('item-REVALIDATE_ITEM', data)
    })

    this.$on('footer-DUPLICATE_ITEM', (item) => {
      this.$parent.$emit('item-DUPLICATE_ITEM', item)
    })

    this.$on('footer-ITEM_UPDATED', (item) => {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    })
  }
}
</script>

<style lang="scss">
  .gap-class .editor__content {
    border-bottom: 1px solid $primary;
    border-right: 1px solid $primary;
    border-left: 1px solid $primary;
    padding: 0 10px;
  }

  .magnetic-gap-class .editor__content {
    border-bottom: 1px solid $warning;
    border-right: 1px solid $warning;
    border-left: 1px solid $warning;
    padding: 0 10px;
  }

  .ProseMirror-focused {
    padding-bottom: 5px;
  }
</style>
